<template>
    <b-card
      :title="prvCompetition.name"
    >
      <b-card-body>
        <p v-html="intro_message"></p>
        <p v-html="message"></p>
<!--
        <div v-if="prvCompetition.registrationMode == 'N'">
          Cette compétition est ouverte à tous les licenciés en règle (à jour de leur licence {{ prvCompetition.year}} avec option "carte compétition") et ne requiert pas de pré-inscription.
        </div>
        <div v-if="prvCompetition.isOpen">
            Cette compétition est prévue d'être "open", ce qui signifie qu'elle acceptera également des compétiteurs non-licenciés (à la FFVL) ou des licensiées n'étant pas à jour de leur licence au premier jour de la compétition. Elle fera donc l'objet de 2 classements (un classement général et un classement fédéral). Pour rappel, seuls les compétiteurs en règle (à jour de leur licence {{ prvCompetition.year}} avec option "carte compétition" valide) seront pris en considération dans le classement "fédéral" afin de prétendre à un éventuel titre ou une éventuelle qualification.
        </div>
        <div v-if="prvCompetition.isOpen && prvCompetition.registrationMode == 'S'">
            Compte-tenu que cette compétition est théoriquement réservée au seuls qualifiés, les compétiteurs désirant participer en "open" sont priés de se faire connaître dès que possible auprès de l'organisateur."
        </div>
        <div v-if="prvCompetition.isOpen && prvCompetition.registrationMode == 'R'">
          Les candidats souhaitant participer en "open" doivent également se faire connaître auprès de l'organisateur en se pré-inscrivant.
        </div>
        </p>
-->
        <!--<last-update v-model="prvLastUpdate" @refresh="refresh" />-->
      </b-card-body>
    </b-card>
  
  </template>
  
  <script>
  
  export default
  {
    props: {
      value: {type: Object, required: true},
      lastUpdate: { type: Date, required: true},
    },
    data() {
      return {
        prvCompetition: this.value,
        prvLastUpdate: this.lastUpdate,
      }
    },
    computed: {
      intro_message(){
        var msg = this.$t("upcoming-competition-card.intro_message", {
          period: this.period,
          location: this.prvCompetition.location
        });
        //console.log('intro_message', msg);
        return msg;
      },
      message(){
        var qualification_authority = this.prvCompetition.isFederal ? this.$t("common.theFFVL") : this.$t("common.theOrganizer");
        var subscription_manager = this.prvCompetition.isFederal ? this.$t("common.theFFVL") : this.$t("common.theOrganizer");
        var except_opens = this.prvCompetition.isOpen ? this.$t("upcoming-competition-card.registration_mode_" + this.prvCompetition.registrationMode + "_exception_opens") : "";
        var licensee_condition = this.prvCompetition.isFederal
                        ? this.$t("upcoming-competition-card.registration_mode_" + this.prvCompetition.registrationMode + "_licence_condition",
                            {
                              competition_year: this.prvCompetition.year,
                              except_opens: except_opens,
                            })
                        : "";  
        var msg = this.$t("upcoming-competition-card.registration_mode_" + this.prvCompetition.registrationMode + "_message",
          {
            qualification_authority: qualification_authority,
            licensee_condition: licensee_condition,
            subscription_manager: subscription_manager,
          });
        return msg;
      },
      period(){
        var from = new Date(this.prvCompetition.predicted_start_date);
        if(this.prvCompetition.predicted_end_date == this.prvCompetition.predicted_start_date)
          return this.$t('period.on-bold', {date : this.$d(from, 'longDay')});
        else{
            var to = new Date(this.prvCompetition.predicted_end_date);
            return this.$t('period.range-bold', { from: this.$d(from, 'longDay'), to: this.$d(to, 'longDay')})
        }
      },
    },
  }
  </script>
  